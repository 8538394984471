import type { SizeData } from '@design-system/components/Pdp/PdpFitFinder.props'

type SubCategory = {
  sizes?: string[]
}
type Category = {
  name?: string
  subCategories?: SubCategory[]
}
import countries from '../data/sizes/size-countries.json'

export const useSizeRetrieveData = async () => {
  const { currentWebsite } = useWebsite()

  const getSizes = async (items: string[]) => {
    const { data } = await useFetch<Record<string, SizeData>>(
      '/api/getSizesBySizeCode/getSizesByCode',
      {
        method: 'POST',
        body: { sizeCodes: items },
        key: currentWebsite.value,
        getCachedData: (key, nuxtApp) => nuxtApp.payload.data[key],
      }
    )

    const sizeMap = new Map<string, SizeData>()
    const sizes = data.value
    if (sizes) {
      Object.keys(sizes).forEach(key => sizeMap.set(key, sizes[key]))
    }
    return sizeMap
  }

  const getSizesByCode = async (sizeCodeId: string) => {
    return await useFetch(`/api/getSizes/${sizeCodeId}`, {
      immediate: true,
      getCachedData: (key, nuxtApp) => nuxtApp.payload.data[key],
    })
  }

  const { data } = await useSizesConfig('sizes-config')

  if (!data.value) {
    throw new Error('No data found')
  }

  // casting to category array since we know the response is deep
  const macroCategories = data.value.content
    .propertyName1 as unknown as Category[]

  const categories =
    macroCategories?.map((category: Category) => {
      const subcategories =
        category.subCategories?.flatMap(
          subcategory => subcategory.sizes ?? []
        ) ?? []

      return {
        name: category.name ?? '',
        subcategories,
      }
    }) ?? []

  return {
    countries,
    macroCategories,
    categories,
    getSizes,
    getSizesByCode,
  }
}
