<script setup lang="ts" generic="Tab extends BaseTab">
import type { BaseTab, UtilsTabsProps } from './UtilsTabs.props'

const props = defineProps<UtilsTabsProps<Tab>>()

const emit = defineEmits<{
  change: [id: number]
  'update:modelValue': [id: number]
}>()

const selectedIndex = ref(props.modelValue ?? props.defaultIndex ?? 0)

watch(
  () => props.modelValue,
  modelValue => {
    if (modelValue) {
      selectedIndex.value = modelValue
    }
  }
)

const onChange = (index: number) => {
  selectedIndex.value = index
  emit('change', index)
  emit('update:modelValue', index)
}
</script>

<template>
  <HeadlessTabGroup as="div" :selected-index="selectedIndex" @change="onChange">
    <HeadlessTabList :class="classButtonsContainer" class="overflow-x-auto">
      <HeadlessTab
        v-for="(tab, index) of tabs"
        :key="index"
        v-slot="{ selected, disabled }"
        :disabled="tab.disabled"
        as="template"
      >
        <slot
          name="button"
          :tab="tab"
          :index="index"
          :selected="selected"
          :disabled="disabled"
        />
      </HeadlessTab>
      <slot name="tabs-sufix" />
    </HeadlessTabList>

    <HeadlessTabPanels :class="classPanelsContainer">
      <HeadlessTabPanel
        v-for="(tab, index) of tabs"
        :key="index"
        v-slot="{ selected }"
        as="template"
      >
        <slot name="panel" :tab="tab" :index="index" :selected="selected" />
      </HeadlessTabPanel>
    </HeadlessTabPanels>
  </HeadlessTabGroup>
</template>
