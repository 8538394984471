<script setup lang="ts" generic="Value">
import type { InputsRadioWithSlotProps } from './InputsRadioWithSlot.props'

const props = withDefaults(defineProps<InputsRadioWithSlotProps<Value>>(), {
  avEcom: 0,
  avRet: 0,
})
const model = defineModel<Value>()
const slots = useSlots()
const { formatPriceByLocale } = useFormatPrice()
const { currentCurrency } = useCurrency()

const checked = computed(() => {
  if (model.value === undefined) {
    return false
  }
  return model.value === props.value
})

const emit = defineEmits<{
  'update:modelValue': [value: Value]
}>()

const handleClick = () => {
  if (!checked.value) {
    if (!props.disabled && !props.outOfStock) {
      emit('update:modelValue', props.value)
    }
  }
}
</script>

<template>
  <div
    class="p-sm relative flex w-full flex-col gap-1"
    :class="[
      checked ? 'cursor-default' : 'cursor-pointer',
      customClass,
      {
        border: checked && !isSelectSizeComponent,
        '!cursor-not-allowed': disabled || outOfStock,
        'text-center': isSelectSizeComponent,
        'bg-primitives-off-white': !isSelectSizeComponent && !customClass,
      },
    ]"
    @click="handleClick"
  >
    <input
      :id="id"
      type="radio"
      :name="name"
      :checked="checked"
      :disabled="disabled || outOfStock"
      class="peer absolute z-0 opacity-0"
      :aria-label="ariaLabel ?? label"
    />
    <div
      class="ring-primitives-blue relative flex w-full cursor-pointer items-center justify-between gap-3 ring-offset-2 peer-focus-visible:ring-1"
      :class="{
        'cursor-default': checked,
        '!cursor-not-allowed': disabled || outOfStock,
      }"
      :disabled="disabled || outOfStock"
      @click="handleClick"
    >
      <div
        v-if="disabled || outOfStock"
        class="bg-background-secondary absolute z-[9999] h-full w-full opacity-50"
      ></div>
      <span class="flex items-center gap-3">
        <div
          class="flex h-4 w-4 shrink-0 items-center justify-center rounded-full border"
          :class="{
            'bg-neutral-black': checked,
            'opacity-20': disabled || outOfStock,
          }"
        >
          <DefaultIconsCheck
            v-if="checked"
            aria-hidden="true"
            class="text-neutral-ultralight h-full w-full"
          />
        </div>
        <div class="flex gap-1 text-left" :class="{ 'flex-col': extSizes }">
          <label
            :for="id"
            :class="[
              {
                'line-through': disabled || outOfStock,
                '!cursor-not-allowed': disabled || outOfStock,
              },
              checked ? 'cursor-default' : 'cursor-pointer',
              isSelectSizeComponent
                ? 'text-book-6 whitespace-nowrap'
                : 'text-medium-5',
            ]"
          >
            {{ label }}
          </label>
          <span v-if="extraLabel && !extSizes" class="text-light-6">
            {{ extraLabel }}
          </span>
          <div
            v-if="extSizes"
            class="text-light-6 text-primitives-grey-200 z-[9999]"
          >
            {{ `${$ts('pdp.sizeSelector.measures')}: ${dims}` }}
            <slot name="extra-label-left" />
          </div>
        </div>
      </span>
      <div v-if="extSizes" class="flex items-center justify-between">
        <div class="text-light-6">
          <div
            class="text-light-6 flex flex-col"
            :class="{ 'gap-x-2': oldPrice }"
          >
            <span
              v-if="oldPrice"
              class="text-primitives-grey-200 text-light-strike-6 line-through"
            >
              {{ formatPriceByLocale(currentCurrency, oldPrice) }}
            </span>
            <span v-if="price">
              {{ formatPriceByLocale(currentCurrency, price) }}
            </span>
            <span v-if="discount" class="text-medium-6">{{ discount }}</span>
          </div>
        </div>

        <div class="ml-6 w-[50px]">
          <NuxtPicture
            v-if="src"
            provider="cloudinary"
            :src="src"
            class="w-full"
            alt=""
          />
        </div>
      </div>
      <slot name="extra-label" />
    </div>
    <Transition
      enter-active-class="transition-all duration-700 overflow-hidden"
      enter-from-class="transform max-h-0"
      enter-to-class="transform max-h-screen"
      leave-active-class="transition-all duration-200 overflow-hidden"
      leave-from-class="transform  max-h-screen"
      leave-to-class="transform max-h-0"
    >
      <div v-if="(isAlwaysExpanded || checked) && !!slots['extra-content']">
        <slot name="extra-content" />
      </div>
    </Transition>
  </div>
</template>
