export async function useSizesConfig(deliveryKey: string) {
  const localePath = useLocalePath()

  const query = {
    locale: localePath(''),
    vse: useRoute().query.vse,
    contentId: useRoute().query.contentId,
    deliveryKey,
  }

  return useFetch('/api/cms/getSizesConfig', {
    query,
    getCachedData: (key, nuxtApp) => nuxtApp.payload.data[key],
  })
}
